import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      // main: '#58846f', 
      main: '#115345',
      // main: 'rgb(74, 121, 98)',
      contrastText: '#fff',
    },
    light: { 
      main: '#8ec693',
      // main: '#4A7962',
      // main: '#115345',
      contrastText: '#fff',
    },
    medium: { 
      main: '#4A7962',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ffb300',
      // main: "#f1d38b",
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#49C145',
    },
    error: {
      main: '#dd4952',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#66bb6a',
    },
    colors:{
        white: '#ffffff',
        whiteness: 'rgba(255,255,255, .4)',
        black: '#000000',
        grey: '#4C4C4C',
        backgroundgrey: '#F7F7F7',
        lightgrey: '#A4A4A4',
        shadow: 'rgba(0,0,0,.16)',
        lightshadow: 'rgba(112,112,112,.06)'
    }
  },
});  