import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';

import {
    FooterContainer,
    FooterLogo,
    FooterTitle,
    FooterText,
    FooterLink,
    FooterLinkImage,
    FooterSection,
    FooterPhoneContent,
    FooterPhoneIcon,
    FooterCopyright,
    CenterMobile
} from './styled' 
import { scrollToSection } from "utils";

export default function Footer(){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const morelinks = [
        { title:'Inicio', action: () => scrollToSection("banner") },
        { title:'Home Care', action:() => scrollToSection("homecare") },
        { title:'Serviços', action:() => scrollToSection("services") },
        { title:'Sobre', action: () => scrollToSection("about") },
        { title:'Contato', action:() => scrollToSection("footer")  },
    ]
    
    const links = [
        { title:'Email', action: () => window.open("mailto:heloisaanddrade@gmail.com", "new") },
        { title:'Whatsapp', action: () => window.open("https://wa.me/61998333005", "new") },
        { title:'LinkedIn', action:() => window.open("https://www.linkedin.com/in/helo%C3%ADsa-andrade-084a6820b/", "new")  },
        { title:'Instagram', action:() => window.open("https://www.instagram.com/draheloisaandrad_", "new") },
    ]

    return ( 
        <> 
            <FooterContainer id="footer">
                <Container>
                    <Row>
                        <Col md={{size:6}}>
                            <CenterMobile>
                                <FooterLogo />
                            </CenterMobile>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{size:6}}>
                            <CenterMobile>
                                <FooterTitle>HA Saúde e Movimento</FooterTitle>
                                <FooterText>Lago Norte, Brasília/DF</FooterText>
                                <FooterPhoneContent>
                                    <FooterLink onClick={() =>  window.open("https://wa.me/61998333005", "new")}>+55 (61) 99833-3005</FooterLink>
                                </FooterPhoneContent>
                            </CenterMobile>
                        </Col>
                        <Col md={{size:6}}>
                            <Row>
                                <Col md={{size:6}}>
                                    <FooterSection> 
                                        {
                                            links?.map((m, k) => 
                                                <FooterLink key={k} onClick={m?.action}>{ m?.title }</FooterLink>
                                            )
                                        }
                                    </FooterSection>
                                </Col>
                                <Col md={{size:6}}>
                                    <FooterSection> 
                                        {
                                            morelinks?.map((m, k) => 
                                                <FooterLink key={k} onClick={m?.action}>{ m?.title }</FooterLink>
                                            )
                                        }
                                    </FooterSection>
                                </Col>
                                {/* <Col md={{size:6}}> 
                                    <FooterSection>
                                        <FooterTitle>Nos siga nas redes</FooterTitle>
                                        <FooterLink>
                                            <FooterLinkImage src={'/icons/insta.svg'} />
                                            Instagram
                                        </FooterLink>
                                        <FooterLink>
                                            <FooterLinkImage src={'/icons/face.svg'} />
                                            Facebook
                                        </FooterLink> 
                                    </FooterSection>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col > 
                            <FooterCopyright>Dra. Heloísa Andrade @{new Date().getFullYear()}</FooterCopyright> 
                        </Col> 
                    </Row>
                </Container>
            </FooterContainer>
        </>
    );
}