import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           

    position: relative;
    min-height: calc(100vh - 73px);
    width: 100%;

    background: white url(/images/dra.png) no-repeat bottom+75% right+15% / 67vh;
    overflow: hidden;
    margin-top: 73px;
    
    @media(max-width: 767px){
        background: white url(/images/dra.png) no-repeat bottom+75% right+10% / 70vh;
    }
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: calc(100vh - 73px);
    width: 100%;

    // background: ${props => props.theme.palette.colors.shadow }; 
    mix-blend-mode: multiply;
`; 

export const BannerContent = styled.div.attrs({ 
})`           
    min-height: calc(100vh - 73px);
    width: 100%; 

    // background: -moz-linear-gradient(130deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // background: -webkit-linear-gradient(130deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // background: linear-gradient(130deg, ${ props => props.theme.palette.primary.main } -50%, rgba(41,28,68,0) 60%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${ props => props.theme.palette.primary.main }",endColorstr="rgba(41,28,68,0)",GradientType=1);
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(max-width: 767px){
        justify-content: flex-end;
    }
`; 
 

export const BannerCard = styled.div.attrs({ 
})`           
`; 