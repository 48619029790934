import React, { useState } from "react"; 

import {
    MoreInfosItemContent,
    MoreInfosImage,
    MoreInfosItemText,
    MoreInfosItemTitle,
    MoreTop
} from './styled' 
import { Col } from "reactstrap";
import { scrollToSection } from "utils";

export default function MoreInfosItem(props){ 

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open); 
        scrollToSection("services")
        if(typeof props.setOpened === 'function'){ props.setOpened(!open ? props.id : null) ;}
    }

    return ( 
        <> 
            <Col md={{ size: open ? 12 : ((props.variant === 0 || props.variant === 5 ) ? 6 : 3) }}> 
                <MoreInfosItemContent secondary={props.secondary}  open={open}  onClick={handleOpen}>
                    <MoreTop open={open} >
                        <MoreInfosImage image={props.image} open={open}  />
                        <MoreInfosItemTitle>
                            { props.title }
                        </MoreInfosItemTitle>
                    </MoreTop>
                    { !open ? null : <MoreInfosItemText open={open} small dangerouslySetInnerHTML={{ __html:props.text }}></MoreInfosItemText> }
                </MoreInfosItemContent> 
            </Col>
        </>
    );
}