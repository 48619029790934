import styled from 'styled-components' 

export const MoreContainer = styled.div.attrs({ 
})`          
    padding: 20vh 0;
    position: relative;


    @media(max-width: 767px){
        padding: 0 0 10vh;
    }
`;
  
export const MoreRow = styled.div.attrs({ 
})`          
    padding: 40px 0 ;
    background: ${ props => props.theme.palette.primary.main };
`;
 
export const MoreTitle = styled.div.attrs({ 
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.white };
`;
 
export const MoreLine = styled.div.attrs({ 
})`          
    margin: 10px 0 20px;
    height: 2px;
    border-radius: 1px;
    background: ${ props => props.theme.palette.colors.white };
    margin-right: 150px;
`;
 
export const MoreText = styled.div.attrs({ 
})` 
    font-size: 16px; 
    color: ${ props => props.theme.palette.colors.white };
`;
 
export const MoreLogoCircle = styled.div.attrs({ 
})` 
    max-width: 398px;
    width: 100%;
    min-height: 398px;
    max-height: 398px;
    border-radius: 50%;
    background:  ${ props => props.theme.palette.colors.white } url(/images/banner.jpg) no-repeat top center / cover;
    box-shadow: 0px 14px 12px ${ props => props.theme.palette.colors.shadow };
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -50px 0 -50px auto;
    overflow: hidden;

    @media(max-width: 767px){
        margin: 50px 0 -100px auto;
    }
`;
 
 
export const MoreLogo = styled.div.attrs({ 
    src:'/images/banner-color.jpg'
})` 
    width: 100%;
    object-fit: cover;
`;
 
export const MoreDecoration = styled.img.attrs({ 
    src:'/icons/circle-2.svg'
})` 
    width: 420px;
    position: absolute;
    top: 0px;
    right: -140px;
    z-index: -1;


    @media(max-width: 767px){
        display: none;
    }
`;
 