import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap';  

import Item from './Item'

import {
    MoreInfosContainer,
    MoreInfosDecoration,
    MoreInfosTitle,
    MoreInfosText
} from './styled'

export default function MoreInfos(){ 
    
    const [opened, setOpened] = useState(null)
    
    const moreInfos = [
        { id:1, title: 'Laserterapia', text: `• <b>Redução da dor</b>: Diminui a dor ao liberar endorfinas e melhorar a circulação na área tratada. <br /><br />
            • <b>Aceleração da cicatrização</b>: Estimula a produção de colágeno e aumenta a regeneração celular. <br /><br />
            • <b>Diminuição da inflamação</b>: Reduz o inchaço e a inflamação ao melhorar a circulação e o fluxo linfático. <br /><br />
            • <b>Melhora da circulação</b>: Facilita o fluxo de sangue e oxigênio nos tecidos, acelerando o processo de recuperação. <br /><br />
            • <b>Estimulação do sistema imunológico</b>: Potencializa a resposta imunológica, ajudando na recuperação de lesões. <br /><br />
            • <b>Aplicação não invasiva</b>: Técnica segura e sem dor, com mínimo ou nenhum efeito colateral. <br /><br />
            • <b>Redução da necessidade de medicamentos</b>: Pode diminuir a necessidade de analgésicos e anti-inflamatórios.`, image: '/images/serv1.jpg' },
        { id:2, title: 'Curativos', text: `
            Cada machucado é uma oportunidade para cuidar melhor da sua pele. Com nossos curativos, você garante uma barreira segura e confortável, que protege contra infecções e acelera a cicatrização. Seja para cortes, arranhões, pequenos ferimentos do dia a dia ou feridas cirúrgicas , nossa linha de curativos combina tecnologia antisséptica e materiais respiráveis, oferecendo uma recuperação mais rápida e sem complicações.
        `, image: '/images/services/2.jpg'  },
        { id:3, title: 'Terapias Manuais', text: `
        Descubra o equilíbrio perfeito entre relaxamento, saúde e bem-estar com técnicas que transformam seu corpo e sua mente. Conheça os benefícios dessas poderosas terapias!
        <br /><br /> 
        💆‍♀️ Massagem Relaxante<br />
        Alivia o estresse e tensões do dia a dia<br />
        Melhora a qualidade do sono<br />
        Promove relaxamento muscular profundo<br />
        Aumenta a sensação de bem-estar
        <br /><br /> 
        💧 Drenagem Linfática<br />
        Reduz o inchaço e melhora a circulação<br />
        Elimina toxinas do organismo<br />
        Estimula o sistema imunológico<br />
        Ideal para pós-operatório e retenção de líquidos
        <br /><br /> 
        🔗 Liberação Miofascial<br />
        Alivia dores crônicas e melhora a mobilidade<br />
        Reduz aderências musculares e tensões acumuladas<br />
        Aumenta a flexibilidade e a performance física<br />
        Ideal para quem pratica atividades físicas ou sofre de dores localizadas
        `, image: '/images/services/3.jpg'  },
        { id:4, title: 'Eletroterapia', text: `A eletroterapia é uma técnica amplamente utilizada na área da saúde, na enfermagem ela é utilizada para melhorar a circulação sanguínea, diminuição da dor e edema e acelerar o processo de cicatrização de feridas. Durante a aplicação de correntes elétricas de baixa intensidade, essa terapia estimula a regeneração tecidual, sendo especialmente útil em casos de feridas crônicas ou de difícil cicatrização, como úlceras de pressão e feridas diabéticas.
        <br /><br /> 
        Para a cicatrização, a eletroterapia estimula células fundamentais como os fibroblastos e os macrófagos. Os fibroblastos são responsáveis pela produção de colágeno, uma proteína crucial para a reparação da pele e dos tecidos danificados.
        `, image: '/images/services/4.jpg'  },
        { id: 5, title: 'Pós-Operatório', text: `
            Benefícios da Estomaterapia no Pós-Operatório:<br /><br /> 
            🔹 Cuidado especializado com estomas: Orientação e manejo para evitar complicações, como irritações e vazamentos.<br /><br /> 
            🔹 Cicatrização eficiente: Tratamento de feridas com técnicas e produtos específicos, acelerando o processo de cura.<br /><br /> 
            🔹 Prevenção de infecções: Higiene e cuidado detalhado para reduzir riscos e garantir uma recuperação segura.<br /><br /> 
            🔹 Apoio emocional: Suporte personalizado para lidar com as mudanças no corpo e na rotina.<br /><br /> 
            🔹 Melhora da qualidade de vida: Acompanhamento contínuo para proporcionar conforto e confiança durante a recuperação.
        `, image: '/images/services/5.jpg'  },
        { id: 6, title: 'Administração de Medicamentos', text: `
            ENFERMEIRO PARA ADMINISTRAÇÃO DE MEDICAMENTOS 💉
            <br /><br /> 
            Cuidar da saúde no conforto do lar pode ser ainda mais eficaz com o suporte de um enfermeiro qualificado para a administração de medicamentos. Com experiência e atenção aos detalhes, o profissional garante que o tratamento seja seguido corretamente, proporcionando tranquilidade para você e sua família.
            <br /><br /> 
            🌟 Benefícios de Contar com um Enfermeiro em Home Care:<br /><br /> 
            🔹 Administração precisa e segura: Reduz o risco de erros, como doses equivocadas ou horários inadequados.<br /><br /> 
            🔹 Acompanhamento personalizado: Observação cuidadosa de reações ao tratamento e ajustes conforme necessário.<br /><br /> 
            🔹 Orientação e educação: Esclarecimento de dúvidas sobre os medicamentos e o plano terapêutico.<br /><br /> 
            🔹 Redução de complicações: Controle rigoroso que previne efeitos adversos ou interações medicamentosas.<br /><br /> 
            🔹 Conforto e praticidade: Cuidado profissional no conforto do lar, sem deslocamentos desnecessários.
        `, image: '/images/services/6.jpg'  },
    ]

    return ( 
        <> 
            <MoreInfosContainer id="services"> 
                {/* <MoreInfosDecoration /> */}
                <Container>
                    <Row>
                        <Col> 
                            <MoreInfosTitle>
                                Serviços
                            </MoreInfosTitle>  
                            <MoreInfosText>
                                Oferecemos cuidados especializados. Nossa missão é proporcionar saúde, bem-estar e recuperação, utilizando tecnologias de ponta e soluções eficazes para cada necessidade.
                            </MoreInfosText>  
                        </Col>
                    </Row> 
                    <Row>
                        {
                            moreInfos.map((item, key) => 
                                !opened || (opened === item?.id) ? <Item key={key} variant={key} { ...item } secondary={ key % 2 !== 0 && false} setOpened={setOpened} /> : null
                            )
                        }
                    </Row>
                </Container>
            </MoreInfosContainer>
        </>
    );
}