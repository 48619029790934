import styled from 'styled-components' 

export const MoreInfosItemContent = styled.div.attrs({ 
})`    
    margin-top: 27px;
    border-radius: 23px;
    background: ${ props => props.theme.palette.primary.main };
    ${
        props => props.secondary ? `
            background: ${ props.theme.palette.secondary.main };
        ` : ``
    }
    overflow: hidden;
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`; 
 
export const MoreInfosImage = styled.div.attrs({ 
})`    
    height: 200px;
    background: rgba(255,255,255,.1) url(${props => props.image}) no-repeat center center / cover;

    transition: all .3s ease;
    ${
        p => p.open ? `
                height: 320px;
                aspect-ratio: 1 / 1 ;
                border-radius: 50%;
                margin: -120px 0 0 -80px;
            ` : ``
    }
`; 
 
 
export const MoreTop = styled.div.attrs({ 
})`    

    ${
        p => p.open ? `
            display: flex;
        ` : ``
    }
`; 
 
 
export const MoreInfosItemTitle = styled.div.attrs({ 
})`    
    font-size: 20px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.white };
    text-align: center;
    margin: 20px 30px 20px;
    transition: all .3s ease;
    ${
        p => p.small ? `
            font-size: 14px;
            font-weight: 400;
            margin: 18px 24px 20px;
            text-align: left;
        ` : ``
    }
    ${
        p => !p.open && p.small ? `
                height: 0px;
            ` : ``
    }
    overflow: hidden;
`; 
 


export const MoreInfosItemText = styled.div.attrs({ 
})`    
    font-size: 20px;
    font-weight: bold;
    background: ${ props => props.theme.palette.colors.white };
    color: ${ props => props.theme.palette.primary.main };
    text-align: center;
    margin: 0px 30px 0;
    transition: all .3s ease;
    ${
        p => p.small ? `
            font-size: 18px;
            font-weight: 400;
            margin: 18px 0px 0px;

            padding: 18px 24px 20px;
            text-align: left;
        ` : ``
    }
    ${
        p => !p.open && p.small ? `
                height: 0px;
            ` : ``
    }
    overflow: hidden;
`; 
 